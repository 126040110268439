import React from "react";
// import { useForm } from "react-hook-form";
import Logo from '../../assets/img/logo.png'
import qs from 'qs';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import { Spinner } from 'evergreen-ui';
import axios from 'axios';
const cookies = new Cookies();

export default class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            phone: '',
            password: '',
            spinBtn:false,
        }
    }
    // const { register, handleSubmit, errors } = useForm();

    signin() {
        axios.post(host + `users/auth/login`,
            qs.stringify({
                phone: this.state.phone,
                password: this.state.password
            }), {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }).then(response => {
            console.log(response.data.data.token);
            cookies.set("token", response.data.data.token, { expires: new Date(Date.now() + 604800000) });
            window.location.href = "/Clients";

        })
            .catch(error => {
                console.log(error.response);
            });
    }
    render() {
        return (
            <div className="loginContiner">
                <div className="loginContiner1" >
                    <img src={Logo} alt='' className='logoLogin' />
                    <form onSubmit={(e) => 
                        this.signin(e)} action="#">
                        <div id='inputNewLoginContiner'>
                            <input autoComplete='off' id='inputNewLogin' type='text' placeholder='رقم الهاتف'
                                onChange={(e) => this.setState({ phone: e.target.value })} />
                            <input id='inputNewLogin' type='password' placeholder='كلمة المرور'
                                onChange={(e) => this.setState({ password: e.target.value })} />
                        </div>
                        <div id='BtnNewLoginContiner'>
                            {!this.state.spinBtn ?
                                <input type="submit" id='BtnNewLogin' value='تسجيل الدخول'
                                    onClick={(e) => {
                                        this.setState({ spinBtn: true })
                                        this.signin(e)
                                    }} />
                                :
                                <div id='BtnNewLogin' ><Spinner size={24} /></div>
                            }
                        </div>
                    </form>


                </div>
            </div>

        )

    }
}