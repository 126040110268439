
import React from 'react';
import Context from '../../assets/js/Context';
import { Spinner } from 'evergreen-ui';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
import { MdArrowBack } from "react-icons/md";
import * as moment from "moment-timezone";
import MaterialDatatable from "material-datatable";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MdAllInclusive } from "react-icons/md";
import { AiOutlineExport, AiOutlineImport } from "react-icons/ai";
import Login from './login';

const cookies = new Cookies();
const columns = [
    { field: "pay", name: "النوع", options: { width: 60, textAlign: 'center', filter: true, sort: false, } },
    { field: "date", name: "التاريخ", options: { filter: true, sort: false, } },
    { field: "price", name: "المبلغ", options: { filter: true, sort: false, } },
    { field: "name", name: "الاسم", options: { filter: true, sort: true, } },
];
class OrderById extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            Orders: [],
            spinPage: true,
            from: new Date(),
            to: new Date(),
            orders_amount0: '',
            orders_amount1: '',
            serch: false,
            NameClient: '',
            PhoneClient: '',
        }
    }
    componentDidMount() {
        var NameClient = localStorage.getItem('NameClient')
        var PhoneClient = localStorage.getItem('PhoneClient')
        var header = { "Content-Type": "application/x-www-form-urlencoded", Authorization: cookies.get("token") };
        axios.get(host + `users/orders/all?client_id=${this.props.match.params.id}&from=${moment(this.state.from).format('DD/MM/YYYY')}&to=${moment(this.state.to).format('DD/MM/YYYY')}`, { headers: header })
            .then(res => {
                // console.log(res.data.data.orders);
                let arr = [];
                for (let i = 0; i < res.data.data.orders.length; i++) {

                    let obj = {
                        name: <Link to={`/OrderById/${res.data.data.orders[i].id}`} onClick={() => localStorage.setItem('returnTo', `ClientById/${res.data.data.orders[i].client.id}`)}>{res.data.data.orders[i].client.name} </Link>,
                        price: res.data.data.orders[i].amount,
                        pay: res.data.data.orders[i].is_out ? <span id='typeTab' style={{ backgroundColor: '#48C9B0' }}>استلام</span> : <span id='typeTab' style={{ backgroundColor: '#EC7063' }}>دفع</span>,
                        date: moment(res.data.data.orders[i].createdAt).tz('Asia/baghdad').format('YYYY/MM/DD'),
                    };
                    arr.push(obj);
                }
                this.setState({
                    Orders: arr, spinPage: false, data: res.data.data, NameClient, PhoneClient,
                });
            })
            .catch(error => { console.log(error) })
        axios.get(host + `users/orders/reports?client_id=${this.props.match.params.id}&from=${moment(this.state.from).format('DD/MM/YYYY')}&to=${moment(this.state.to).format('DD/MM/YYYY')}&is_out=0`, { headers: header })
            .then(res => {
                this.setState({
                    orders_amount0: res.data.data.orders_amount,
                });
            })
            .catch(error => { console.log(error) })
        axios.get(host + `users/orders/reports?client_id=${this.props.match.params.id}&from=${moment(this.state.from).format('DD/MM/YYYY')}&to=${moment(this.state.to).format('DD/MM/YYYY')}&is_out=1`, { headers: header })
            .then(res => {
                this.setState({
                    orders_amount1: res.data.data.orders_amount,
                });
            })
            .catch(error => { console.log(error) })
    }
    getMuiTheme = () => createMuiTheme({
        overrides: {
            MaterialDatatableBodyCell: {
                root: { textAlign: 'right', },
            },
            MuiTableCell: {
                root: { textAlign: 'right', },
                // head: {  backgroundColor: "#FF0000", }
            },
        }
    })
    handleChangeFrom = date => {
        this.setState({
            from: date
        });
    };
    handleChangeTo = date => {
        this.setState({
            to: date
        });
    };
    render() {
        return (
            <Context.Consumer>
                {ctx => {
                    if (cookies.get("token")) {
                        if (!this.state.spinPage) {
                            return (
                                <div>
                                    <div className='navvBarContinerN'>
                                        <div className='TitlNav'>
                                            <span> {this.state.NameClient}&nbsp; </span>
                                   /
                                    <span> &nbsp;{this.state.PhoneClient} </span>
                                        </div>
                                        <Link to={`/Clients`}>
                                            <MdArrowBack style={{ color: '#fff', fontSize: 25 }} />
                                        </Link>
                                    </div>

                                    <div id='SelectStatusContinerDRivNew'>
                                        <Row id='RowReportDate' style={{direction: 'rtl'}}>
                                            <Col id='ColReportDate' sm={12} md={6} xl={4}>
                                                <span>من </span>
                                                <DatePicker id='date1Repo'
                                                    dateFormat="yyyy/MM/dd"
                                                    selected={this.state.from}
                                                    onChange={this.handleChangeFrom}
                                                />
                                            </Col>
                                            <Col id='ColReportDate' sm={12} md={6} xl={4}>
                                                <span>الى </span>
                                                <DatePicker id='date2Repo'
                                                    dateFormat="yyyy/MM/dd"
                                                    selected={this.state.to}
                                                    onChange={this.handleChangeTo}
                                                />
                                            </Col>
                                            <Col id='ColReportDate' sm={12} md={6} xl={4}>
                                                <div id='AddClientDeptBTN' onClick={() => {
                                                    this.setState({ spin: true, serch: true })
                                                    this.componentDidMount()
                                                }}>بحث</div>
                                            </Col>
                                        </Row>
                                    </div>

                                    {this.state.serch ?
                                        <div style={{ direction: 'ltr' }} >
                                            <MuiThemeProvider
                                                theme={this.getMuiTheme()}>
                                                <MaterialDatatable data={this.state.Orders} columns={columns} options={{
                                                    responsive: 'scroll',
                                                    selectableRows: false,
                                                    rowCursorHand: false,
                                                    sort: false,
                                                    filter: false,
                                                    print: false,
                                                    download: false,
                                                    onRowsDelete: false,
                                                    rowsPerPage: 15,
                                                    viewColumns: false,
                                                    textLabels: {
                                                        body: { noMatch: " لم يتم العثور على سجلات مطابقة", toolTip: "فرز", },
                                                        pagination: { next: "الصفحة التالية", previous: "الصفحة السابقة", rowsPerPage: "عدد الصفوف", displayRows: "من", },
                                                        toolbar: { search: "بحث", downloadCsv: "تنزيل", print: "Print", viewColumns: " التحكم بالاعمدة", filterTable: "فلتر", },
                                                        filter: { all: "الكل", title: "فلتر", reset: "إعادة تعيين", },
                                                        viewColumns: { title: "عرض الأعمدة", titleAria: "إظهار / إخفاء أعمدة الجدول", },
                                                    },
                                                }} />
                                            </MuiThemeProvider>
                                            <Row style={{ margin: 0, width: '100%', direction: 'rtl' }}>
                                                <Col className='infoClientCol' sm={6} md={4} lg={4}>
                                                    <div className='cellInfoCardContiner' style={{ backgroundColor: '#48C9B0' }}>
                                                        <div className='cellInfoCardContiner1' >
                                                            <h6>الطلبات المستلمة</h6>
                                                            <h6>{this.state.orders_amount1.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h6>
                                                        </div>
                                                        <AiOutlineImport style={{ fontSize: 50 }} />
                                                    </div>
                                                </Col>
                                                <Col className='infoClientCol' sm={6} md={4} lg={4}>
                                                    <div className='cellInfoCardContiner' style={{ backgroundColor: '#EC7063' }}>
                                                        <div className='cellInfoCardContiner1' >
                                                            <h6>الطلبات المدفوعة</h6>
                                                            <h6>{this.state.orders_amount0.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h6>
                                                        </div>
                                                        <AiOutlineExport style={{ fontSize: 50 }} />
                                                    </div>
                                                </Col>
                                                <Col className='infoClientCol' sm={6} md={4} lg={4}>
                                                    <div className='cellInfoCardContiner' style={{ backgroundColor: '#5DADE2' }}>
                                                        <div className='cellInfoCardContiner1' >
                                                            <h6>الصافي</h6>
                                                            <h6>{(this.state.orders_amount1 - this.state.orders_amount0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h6>
                                                        </div>
                                                        <MdAllInclusive style={{ fontSize: 50 }} />
                                                    </div>
                                                </Col>

                                            </Row>
                                        </div>
                                        : null}
                                </div>
                            )
                        }
                        else {
                            return (
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '60vh', width: '100%' }}>
                                    <Spinner />
                                </div>
                            )
                        }
                    }
                    else {
                        return (
                            <Login />
                        )
                    }
                }
                }

            </Context.Consumer >
        )
    }
}

export default OrderById;