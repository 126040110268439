
import React from 'react';
import Context from '../../assets/js/Context';
import { NavLink } from 'react-router-dom';
import { MdSettings, MdSupervisorAccount,MdList,MdShowChart } from "react-icons/md";
class Navbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            office: [],
            location: {
                lat: '',
                lng: '',
            },
            draggable: true,
        }
    }
    render() {
        return (
            <Context.Consumer>
                {ctx => {
                    // if (ctx.value.profile.role === 0) {
                    return (
                        <div className='navvBarContiner'>
                            <div className='navvBarContiner1'>
                                <NavLink to='/Clients'>
                                    <div className='btnNAv'>
                                        <span>العملاء</span>
                                        <MdSupervisorAccount style={{ fontSize: 20 }} />
                                    </div>
                                </NavLink>
                                <NavLink to='/Setting'>
                                    <div className='btnNAv'>
                                        <span>الاعدادات</span>
                                        <MdSettings style={{ fontSize: 20 }} />
                                    </div>
                                </NavLink>
                            </div>
                            <div className='navvBarContiner1'>
                                <NavLink to='/Orders'>
                                    <div className='btnNAv'>
                                        <span>الطلبات</span>
                                        <MdList style={{ fontSize: 20 }} />
                                    </div>
                                </NavLink>
                                <NavLink to='/Statstic'>
                                    <div className='btnNAv'>
                                        <span>الاحصائيات</span>
                                        <MdShowChart style={{ fontSize: 20 }} />
                                    </div>
                                </NavLink>
                            </div>
                        </div>
                    )
                    // }
                    // else {
                    //     return (
                    //         <Login />
                    //     )
                    // }
                }
                }

            </Context.Consumer >
        )
    }
}

export default Navbar;