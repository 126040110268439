import React from 'react';
import ScrollToTop from './assets/js/scrolToTop';
import Context from './assets/js/Context';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import NotFound from './assets/js/NotFound';
import Login from './component/common/login';
import Setting from './component/setting';
import Clients from './component/Client';
import Orders from './component/orders';
import Statstic from './component/statstic';
import ClientById from './component/common/clientById';
import OrderById from './component/common/orderById';
// import Cookies from "universal-cookie";
import './App.css';
// const cookies = new Cookies();

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      
    }
  }
  render() {
    return (
      <div >
        <Context.Provider value={{
          value: this.state, action: {
            // datapdf: (value) => {
            //   this.setState({ pdf: value })
            // },
          }
        }}>
          <div className="App">
            <BrowserRouter>
              <ScrollToTop>
                  <Switch>
                    <Route exact path='/' component={Login} />
                    <Route path='/Setting' component={Setting} />
                    <Route path='/Clients' component={Clients} />
                    <Route path='/Orders' component={Orders} />
                    <Route path='/Statstic' component={Statstic} />
                    <Route path='/OrderById/:id' component={OrderById} />
                    <Route path='/ClientById/:id' component={ClientById} />
                   <Route exact={true} path='*' component={NotFound} />
                  </Switch>
              </ScrollToTop>
            </BrowserRouter>
          </div>
        </Context.Provider>

      </div>
    );
  }
}

export default App;
