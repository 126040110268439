
import React from 'react';
import Context from '../../assets/js/Context';
import { Spinner } from 'evergreen-ui';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
import { MdArrowBack } from "react-icons/md";
import * as moment from "moment-timezone";
import Login from './login';
const cookies = new Cookies();
class OrderById extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            spinPage: true,
            returnTo: '',
        }
    }
    componentDidMount() {
        var returnTo = localStorage.getItem('returnTo')
        var header = { "Content-Type": "application/x-www-form-urlencoded", Authorization: cookies.get("token") };
        axios.get(host + `users/orders/orders/${this.props.match.params.id}?`, { headers: header })
            .then(res => {
                // console.log(res.data.data.order);

                this.setState({
                    data: res.data.data.order, spinPage: false, returnTo
                });
            })
            .catch(error => { console.log(error) })
    }
    render() {
        return (
            <Context.Consumer>
                {ctx => {
                    if (cookies.get("token")) {
                        if (!this.state.spinPage) {
                            return (
                                <div>
                                    <div className='navvBarContinerN' style={this.state.data.is_out ? { backgroundColor: '#48C9B0' } : { backgroundColor: '#EC7063' }}>
                                        <div className='TitlNav'>
                                            <span> {this.state.data.client.name}&nbsp; </span>
                                   /
                                    <span> &nbsp;{this.state.data.client.phone} </span>
                                        </div>
                                        <Link to={`/${this.state.returnTo}`}>
                                            <MdArrowBack style={{ color: '#fff', fontSize: 25 }} />
                                        </Link>
                                    </div>

                                    <Row id='RowReportDate' style={{ direction: 'rtl' }}>
                                        <Col id='ColReportDate' sm={12} md={6} >
                                            <div className='OrderByInfoContiner'>
                                                <label>الماده</label>
                                                <div className='OrderByInfo'>{this.state.data.title}</div>
                                            </div>
                                        </Col>
                                        <Col id='ColReportDate' sm={12} md={6} >
                                            <div className='OrderByInfoContiner'>
                                                <label>المبلغ</label>
                                                <div className='OrderByInfo'>{this.state.data.amount}</div>
                                            </div>
                                        </Col>
                                        <Col id='ColReportDate' sm={12} md={6} >
                                            <div className='OrderByInfoContiner'>
                                                <label>التاريخ</label>
                                                <div className='OrderByInfo'>{moment(this.state.data.createdAt).tz('Asia/baghdad').format('YYYY/MM/DD')}</div>
                                            </div>
                                        </Col>
                                        <Col id='ColReportDate' sm={12} md={6} >
                                            <div className='OrderByInfoContiner'>
                                                <label>النوع</label>
                                                <div className='OrderByInfo'>{this.state.data.is_out ? 'استلام' : 'دفع'}</div>
                                            </div>
                                        </Col>
                                        <Col id='ColReportDate' sm={12} md={6} >
                                            <div className='OrderByInfoContiner'>
                                                <label>التفاصيل</label>
                                                <div className='OrderByInfoDEs'>{this.state.data.description}</div>
                                            </div>
                                        </Col>
                                        <Col id='ColReportDate' sm={12} md={6} >
                                            <div className='OrderByInfoContiner'>
                                                <label>ملف</label>
                                                <img src={host + 'files/' + this.state.data.file} className='OrderByInfoDEsImg' alt='file' />
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            )
                        }
                        else {
                            return (
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '60vh', width: '100%' }}>
                                    <Spinner />
                                </div>
                            )
                        }
                    }
                    else {
                        return (
                            <Login />
                        )
                    }
                }
                }

            </Context.Consumer >
        )
    }
}

export default OrderById;