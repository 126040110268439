import React from 'react';
import Context from '../assets/js/Context';
import Component from "@reactions/component";
import { Pane, Dialog, Spinner } from 'evergreen-ui';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import InfoIcon from '@material-ui/icons/Info';
import MaterialDatatable from "material-datatable";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Cookies from "universal-cookie";
import host from '../assets/js/Host';
import axios from 'axios';
import NavBar from './common/navbar';
import Login from './common/login';
// import io from '../../assets/js/openSocket';
// import Mp3 from '../../assets/img/note.mp3';

const cookies = new Cookies();
const columns = [
    { field: "info", name: "تفاصبل", options: { width: 60, filter: true, sort: false, } },
    { field: "delete", name: "حذف", options: { width: 60, filter: true, sort: false, } },
    { field: "address", name: "العنوان", options: { filter: true, sort: false, } },
    { field: "phone", name: "رقم الهاتف ", options: { filter: true, sort: false, } },
    { field: "name", name: "الاسم", options: { filter: true, sort: true, } },
];
class Statistics extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            undone: [],
            done: [],
            spin: true,
            spinPage: true,
            displayData: [],
        }
        // this.socket();
    }

    componentDidMount() {
        var header = { "Content-Type": "application/x-www-form-urlencoded", Authorization: cookies.get("token") };
        axios.get(host + `users/client/all`, { headers: header })
            .then(res => {
                // console.log(res.data.data.clients);
                let arr = [];
                for (let i = 0; i < res.data.data.clients.length; i++) {
                    let obj = {
                        name: res.data.data.clients[i].name,
                        phone: res.data.data.clients[i].phone,
                        address: res.data.data.clients[i].address,
                        delete: <Component initialState={{ isShown: false }}>
                            {({ state, setState }) => (
                                <Pane>
                                    <Dialog
                                        isShown={state.isShown}
                                        title=" حذف العميل "
                                        intent="danger"
                                        onCloseComplete={() => setState({ isShown: false })}
                                        confirmLabel="موافق"
                                        cancelLabel="الغاء"
                                        onConfirm={() => {
                                            setState({ isShown: false })
                                            this.cancelOrder(res.data.data.clients[i].id)
                                        }}
                                    >
                                        <span id='msgDelete'> 
                                        هل انت متأكد من عملية الحذف <br/> 
                                        سوف يتم حذف جميع الطلبات الخاصه ل {res.data.data.clients[i].name}</span>
                                    </Dialog>
                                    <div id='MenuBtnCateCont' onClick={() => setState({ isShown: true })}>
                                        <DeleteForeverIcon style={{ color: '#d64c4c', cursor: 'pointer' }} />
                                    </div>
                                </Pane>
                            )}
                        </Component>
                        ,
                        info: <Link to={`/ClientById/${res.data.data.clients[i].id}`} onClick={() => {
                            localStorage.setItem('NameClient', res.data.data.clients[i].name)
                            localStorage.setItem('PhoneClient', res.data.data.clients[i].phone)
                        }}>
                            <div id='MenuBtnCateCont' >
                                <InfoIcon style={{ color: '#7cb6f5', cursor: 'pointer' }} />
                            </div>
                        </Link>
                        ,


                    };

                    arr.push(obj);

                }
                this.setState({
                    undone: arr, spinPage: false,
                });
            })
            .catch(error => { console.log(error) })

    }

    getMuiTheme = () => createMuiTheme({
        overrides: {
            MaterialDatatableBodyCell: {
                root: { textAlign: 'right', },
            },
            MuiTableCell: {
                root: { textAlign: 'right', },
                // head: {  backgroundColor: "#FF0000", }
            },
        }
    })
    cancelOrder(id) {
        var header = { "Content-Type": "application/json", Authorization: cookies.get("token") };

        axios.delete(host + `users/user/${id}`, { headers: header })
            .then(response => {
                toast('تم الحذف', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                this.componentDidMount()

            })
            .catch(error => {
                console.log(error.response)
                toast(error.response.data.message, {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            });
    }
    render() {
        if (cookies.get("token")) {
            return (
                <Context.Consumer>
                    {ctx => {
                        return (

                            <div style={{ direction: 'ltr', width: '100%' }}>
                                <NavBar />
                                {!this.state.spinPage ?
                                    <div >
                                        <div className='DataTableContiner'  >
                                            <MuiThemeProvider
                                                theme={this.getMuiTheme()}>
                                                <MaterialDatatable data={this.state.undone} columns={columns} options={{
                                                    selectableRows: false,
                                                    responsive: 'scroll',
                                                    rowCursorHand: false,
                                                    sort: false,
                                                    filter: false,
                                                    print: false,
                                                    download: false,
                                                    viewColumns: false,
                                                    rowsPerPage: 100,
                                                    textLabels: {
                                                        body: { noMatch: " لم يتم العثور على سجلات مطابقة", toolTip: "فرز", },
                                                        pagination: { next: "الصفحة التالية", previous: "الصفحة السابقة", rowsPerPage: "عدد الصفوف", displayRows: "من", },
                                                        toolbar: { search: "بحث", downloadCsv: "تنزيل", print: "Print", viewColumns: " التحكم بالاعمدة", filterTable: "فلتر", },
                                                        filter: { all: "الكل", title: "فلتر", reset: "إعادة تعيين", },
                                                        viewColumns: { title: "عرض الأعمدة", titleAria: "إظهار / إخفاء أعمدة الجدول", },
                                                    },
                                                }} />
                                            </MuiThemeProvider>
                                        </div>
                                    </div>
                                    :
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '60vh', width: '100%' }}>
                                        <Spinner />
                                    </div>}
                                <ToastContainer
                                    position="bottom-left"
                                    autoClose={10000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnVisibilityChange
                                    draggable
                                    pauseOnHover
                                />
                            </div>
                        )
                    }}
                </Context.Consumer>
            )
        }
        else {
            return (
                <Login />
            )
        }
    }
}
export default Statistics;