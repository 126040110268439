import React from 'react';
import Context from '../assets/js/Context';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Cookies from "universal-cookie";
import host from '../assets/js/Host';
import axios from 'axios';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as moment from "moment-timezone";
import MaterialDatatable from "material-datatable";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { MdAllInclusive } from "react-icons/md";
import NavBar from './common/navbar';
import { AiOutlineExport, AiOutlineImport } from "react-icons/ai";
import Component from "@reactions/component";
import { Pane, Dialog, Spinner } from 'evergreen-ui';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Login from './common/login';

const cookies = new Cookies();

const columns = [
    { field: "delete", name: "حذف", options: { width: 60, filter: true, sort: false, } },
    { field: "pay", name: "النوع", options: { width: 60, textAlign: 'center', filter: true, sort: false, } },
    { field: "date", name: "التاريخ", options: { filter: true, sort: false, } },
    { field: "price", name: "المبلغ", options: { filter: true, sort: false, } },
    { field: "name", name: "الاسم", options: { filter: true, sort: true, } },
];


class Statistics extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            attach_client: [],
            Orders: [],
            spin: true,
            spinBtn: false,
            displayData: [],
            dataPdf: [],
            from: new Date(),
            to: new Date(),
            status: '',
            orders_amount0: '',
            orders_amount1: '',
            serch: false,
        }
        // this.socket();
    }

    componentDidMount() {
        var header = { "Content-Type": "application/x-www-form-urlencoded", Authorization: cookies.get("token") };
        axios.get(host + `users/orders/all?from=${moment(this.state.from).format('DD/MM/YYYY')}&to=${moment(this.state.to).format('DD/MM/YYYY')}`, { headers: header })
            .then(res => {
                let arr = [];
                for (let i = 0; i < res.data.data.orders.length; i++) {

                    // console.log(res.data.data.orders[i].client);
                    let obj = {
                        name: <Link to={`/OrderById/${res.data.data.orders[i].id}`} onClick={() => localStorage.setItem('returnTo', 'Orders')}>{res.data.data.orders[i].client.name} </Link>,
                        price: res.data.data.orders[i].amount,
                        pay: res.data.data.orders[i].is_out ? <span id='typeTab' style={{ backgroundColor: '#48C9B0' }}>استلام</span> : <span id='typeTab' style={{ backgroundColor: '#EC7063' }}>دفع</span>,
                        date: moment(res.data.data.orders[i].createdAt).tz('Asia/baghdad').format('YYYY/MM/DD'),
                        delete: <Component initialState={{ isShown: false }}>
                            {({ state, setState }) => (
                                <Pane>
                                    <Dialog
                                        isShown={state.isShown}
                                        title=" حذف الطلب "
                                        intent="danger"
                                        onCloseComplete={() => setState({ isShown: false })}
                                        confirmLabel="موافق"
                                        cancelLabel="الغاء"
                                        onConfirm={() => {
                                            setState({ isShown: false })
                                            this.cancelOrder(res.data.data.orders[i].id)
                                        }}
                                    >
                                        <span id='msgDelete'> هل انت متأكد من عملية الحذف</span>
                                    </Dialog>
                                    <div id='MenuBtnCateCont' onClick={() => setState({ isShown: true })}>
                                        <DeleteForeverIcon style={{ color: '#d64c4c', cursor: 'pointer' }} />
                                    </div>
                                </Pane>
                            )}
                        </Component>
                        ,

                    };
                    arr.push(obj);
                }
                this.setState({
                    Orders: arr, spinPage: false,
                });
            })
            .catch(error => { console.log(error) })
        axios.get(host + `users/orders/reports?is_out=0&from=${moment(this.state.from).format('DD/MM/YYYY')}&to=${moment(this.state.to).format('DD/MM/YYYY')}`, { headers: header })
            .then(res => {
                this.setState({
                    orders_amount0: res.data.data.orders_amount,
                });
            })
            .catch(error => { console.log(error) })
        axios.get(host + `users/orders/reports?is_out=1&from=${moment(this.state.from).format('DD/MM/YYYY')}&to=${moment(this.state.to).format('DD/MM/YYYY')}`, { headers: header })
            .then(res => {
                this.setState({
                    orders_amount1: res.data.data.orders_amount,
                });
            })
            .catch(error => { console.log(error) })

    }
    cancelOrder(id) {
        var header = { "Content-Type": "application/json",  Authorization: cookies.get("token") };

        axios.delete(host + `users/orders/${id}`, { headers: header })
            .then(response => {
                toast('تم حذف الطلب', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                this.componentDidMount()

            })
            .catch(error => {
                console.log(error.response)
                toast(error.response.data.message, {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            });
    }
    getMuiTheme = () => createMuiTheme({
        overrides: {
            MaterialDatatableBodyCell: {
                root: { textAlign: 'right', },
            },
            MuiTableCell: {
                root: { textAlign: 'right', },
                // head: {  backgroundColor: "#FF0000", }
            },
        }
    })
    handleChangeFrom = date => {
        this.setState({
            from: date
        });
    };
    handleChangeTo = date => {
        this.setState({
            to: date
        });
    };

    render() {

        return (
            <Context.Consumer>
                {ctx => {
                    if (cookies.get("token")) {
                        return (

                            <div style={{ direction: 'rtl', width: '100%' }}>
                                <NavBar />

                                <div id='SelectStatusContinerDRivNew'>
                                    <Row id='RowReportDate'>
                                        <Col id='ColReportDate' sm={12} md={6} xl={4}>
                                            <span>من </span>
                                            <DatePicker id='date1Repo'
                                                dateFormat="yyyy/MM/dd"
                                                selected={this.state.from}
                                                onChange={this.handleChangeFrom}
                                            />
                                        </Col>
                                        <Col id='ColReportDate' sm={12} md={6} xl={4}>
                                            <span>الى </span>
                                            <DatePicker id='date2Repo'
                                                dateFormat="yyyy/MM/dd"
                                                selected={this.state.to}
                                                onChange={this.handleChangeTo}
                                            />
                                        </Col>
                                        <Col id='ColReportDate' sm={12} md={6} xl={4}>
                                            <div id='AddClientDeptBTN' onClick={() => {
                                                this.setState({ spin: true, serch: true })
                                                this.componentDidMount()
                                            }}>بحث</div>
                                        </Col>
                                    </Row>
                                </div>
                                {!this.state.spinPage ?
                                    this.state.serch ?
                                        <div style={{ direction: 'ltr' }} >
                                            <MuiThemeProvider
                                                theme={this.getMuiTheme()}>
                                                <MaterialDatatable data={this.state.Orders} columns={columns} options={{
                                                    responsive: 'scroll',
                                                    selectableRows: false,
                                                    rowCursorHand: false,
                                                    sort: false,
                                                    filter: false,
                                                    print: false,
                                                    download: false,
                                                    onRowsDelete: false,
                                                    rowsPerPage: 100,
                                                    viewColumns: false,
                                                    textLabels: {
                                                        body: { noMatch: " لم يتم العثور على سجلات مطابقة", toolTip: "فرز", },
                                                        pagination: { next: "الصفحة التالية", previous: "الصفحة السابقة", rowsPerPage: "عدد الصفوف", displayRows: "من", },
                                                        toolbar: { search: "بحث", downloadCsv: "تنزيل", print: "Print", viewColumns: " التحكم بالاعمدة", filterTable: "فلتر", },
                                                        filter: { all: "الكل", title: "فلتر", reset: "إعادة تعيين", },
                                                        viewColumns: { title: "عرض الأعمدة", titleAria: "إظهار / إخفاء أعمدة الجدول", },
                                                    },
                                                }} />
                                            </MuiThemeProvider>
                                            <Row style={{ margin: 0, width: '100%', direction: 'rtl' }}>
                                                <Col className='infoClientCol' sm={6} md={4} lg={4}>
                                                    <div className='cellInfoCardContiner' style={{ backgroundColor: '#48C9B0' }}>
                                                        <div className='cellInfoCardContiner1' >
                                                            <h6>الطلبات المستلمة</h6>
                                                            <h6>{this.state.orders_amount1.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h6>
                                                        </div>
                                                        <AiOutlineImport style={{ fontSize: 50 }} />
                                                    </div>
                                                </Col>
                                                <Col className='infoClientCol' sm={6} md={4} lg={4}>
                                                    <div className='cellInfoCardContiner' style={{ backgroundColor: '#EC7063' }}>
                                                        <div className='cellInfoCardContiner1' >
                                                            <h6>الطلبات المدفوعة</h6>
                                                            <h6>{this.state.orders_amount0.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h6>
                                                        </div>
                                                        <AiOutlineExport style={{ fontSize: 50 }} />
                                                    </div>
                                                </Col>
                                                <Col className='infoClientCol' sm={6} md={4} lg={4}>
                                                    <div className='cellInfoCardContiner' style={{ backgroundColor: '#5DADE2' }}>
                                                        <div className='cellInfoCardContiner1' >
                                                            <h6>الصافي</h6>
                                                            <h6>{(this.state.orders_amount1 - this.state.orders_amount0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h6>
                                                        </div>
                                                        <MdAllInclusive style={{ fontSize: 50 }} />
                                                    </div>
                                                </Col>

                                            </Row>
                                            <ToastContainer
                                                position="bottom-left"
                                                autoClose={10000}
                                                hideProgressBar={false}
                                                newestOnTop={false}
                                                closeOnClick
                                                rtl={false}
                                                pauseOnVisibilityChange
                                                draggable
                                                pauseOnHover
                                            />
                                        </div>
                                        : null
                                    : <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '60vh', width: '100%' }}>
                                        <Spinner />
                                    </div>}
                            </div>

                        )
                    }
                    else {
                        return (
                            <Login />
                        )
                    }
                }}
            </Context.Consumer>
        )
    }
}
export default Statistics;