import React from 'react';
import Context from '../assets/js/Context';
import { Row, Col } from 'react-bootstrap';
// import { Link } from 'react-router-dom';
import Cookies from "universal-cookie";
import host from '../assets/js/Host';
import axios from 'axios';
import { MdAllInclusive } from "react-icons/md";
import NavBar from './common/navbar';
import { AiOutlineExport, AiOutlineImport } from "react-icons/ai";
import { Spinner } from 'evergreen-ui';
import Login from './common/login';
const cookies = new Cookies();


class Statistics extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orders_amount0: '',
            orders_count0: '',
            orders_amount1: '',
            orders_count1: '',
            spinPage: true,
        }
        // this.socket();
    }

    componentDidMount() {
        // console.log(this.state.status);
        var header = { "Content-Type": "application/x-www-form-urlencoded", Authorization: cookies.get("token") };
        axios.get(host + `users/orders/reports?is_out=0`, { headers: header })
            .then(res => {
                // console.log(res.data.data);
                this.setState({
                    orders_amount0: res.data.data.orders_amount,
                    orders_count0: res.data.data.orders_count,
                    spinPage: false,
                });
            })
            .catch(error => { console.log(error) })
        axios.get(host + `users/orders/reports?is_out=1`, { headers: header })
            .then(res => {
                console.log(res.data.data);
                this.setState({
                    orders_amount1: res.data.data.orders_amount,
                    orders_count1: res.data.data.orders_count,
                    spinPage: false,
                });
            })
            .catch(error => { console.log(error) })
    }
    render() {
        return (
            <Context.Consumer>
                {ctx => {
                    if (cookies.get("token")) {
                        return (

                            <div style={{ direction: 'rtl', width: '100%' }}>
                                <NavBar />
                                {!this.state.spinPage ?
                                    <Row style={{ margin: 0, width: '100%' }}>
                                        <Col className='infoClientCol' sm={6} md={4} lg={3}>
                                            <div className='cellInfoCardContiner' style={{ backgroundColor: '#F7DC6F' }}>
                                                <div className='cellInfoCardContiner1' >
                                                    <h6>الطلبات المستلمة</h6>
                                                    <h6>{this.state.orders_amount1.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h6>
                                                </div>
                                                <AiOutlineImport style={{ fontSize: 50 }} />
                                            </div>
                                        </Col>
                                        <Col className='infoClientCol' sm={6} md={4} lg={3}>
                                            <div className='cellInfoCardContiner' style={{ backgroundColor: '#85C1E9' }}>
                                                <div className='cellInfoCardContiner1' >
                                                    <h6>الطلبات المدفوعة</h6>
                                                    <h6>{this.state.orders_amount0.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h6>
                                                </div>
                                                <AiOutlineExport style={{ fontSize: 50 }} />
                                            </div>
                                        </Col>
                                        <Col className='infoClientCol' sm={6} md={4} lg={3}>
                                            <div className='cellInfoCardContiner' style={{ backgroundColor: '#76D7C4' }}>
                                                <div className='cellInfoCardContiner1' >
                                                    <h6> عدد المستلمة</h6>
                                                    <h6>{this.state.orders_count1}</h6>
                                                </div>
                                                <AiOutlineImport style={{ fontSize: 50 }} />
                                            </div>
                                        </Col>
                                        <Col className='infoClientCol' sm={6} md={4} lg={3}>
                                            <div className='cellInfoCardContiner' style={{ backgroundColor: '#EDBB99' }}>
                                                <div className='cellInfoCardContiner1' >
                                                    <h6> عدد المدفوعة</h6>
                                                    <h6>{this.state.orders_count0}</h6>
                                                </div>
                                                <AiOutlineExport style={{ fontSize: 50 }} />
                                            </div>
                                        </Col>
                                        <Col className='infoClientCol' sm={6} md={4} lg={3}>
                                            <div className='cellInfoCardContiner' style={{ backgroundColor: '#45B39D' }}>
                                                <div className='cellInfoCardContiner1' >
                                                    <h6>الصافي</h6>
                                                    <h6>{(this.state.orders_amount1 - this.state.orders_amount0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h6>
                                                </div>
                                                <MdAllInclusive style={{ fontSize: 50 }} />
                                            </div>
                                        </Col>

                                    </Row>
                                    : <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '60vh', width: '100%' }}>
                                        <Spinner />
                                    </div>}
                            </div>

                        )
                    }
                    else {
                        return (
                            <Login />
                        )
                    }
                }}
            </Context.Consumer>
        )
    }
}
export default Statistics;