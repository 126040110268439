
import React from 'react';
import Context from '../assets/js/Context';
import { Row, Col, Form } from 'react-bootstrap';
import Cookies from "universal-cookie";
import Component from "@reactions/component";
import { FilePicker, Dialog, Pane } from 'evergreen-ui';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
// import { Link } from 'react-router-dom';
import NavBar from './common/navbar';
import { FaUserPlus, FaSignOutAlt } from "react-icons/fa";
import { MdPlaylistAdd } from "react-icons/md";
import host from '../assets/js/Host';
import axios from 'axios';
import qs from 'qs';
import Login from './common/login';
const cookies = new Cookies();
class Setting extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            location: {
                lat: '',
                lng: '',
            },
            draggable: true,
        }
    }
    componentDidMount() {
        var header = { "Content-Type": "application/json", Authorization: cookies.get("token") };
        axios.get(host + `users/client/all`, { headers: header })
            .then(res => {
                var arr = [];
                // console.log(res.data.data.clients);
                for (let i = 0; i < res.data.data.clients.length; i++) {
                    arr.push(
                        {
                            value: res.data.data.clients[i].id,
                            label: res.data.data.clients[i].name,
                        }
                    )
                }

                this.setState({
                    users: arr
                });
            })
            .catch(error => { console.log(error.response) })
    }
    render() {
        return (
            <Context.Consumer>
                {ctx => {
                    if (cookies.get("token")) {
                        return (
                            <div >
                                <NavBar />
                                <div id='contentSeettingContiner' style={{ width: '100%', direction: 'rtl' }}>
                                    <Row style={{ margin: 0, width: '100%' }}>
                                        <Col id='colSetting' sm={12} md={6} lg={4} xl={3} >

                                            <Component initialState={{ isShown: false, spinBtn: false, name: '', phone: '', address: '' }}>
                                                {({ state, setState }) => (
                                                    <Pane>
                                                        <Dialog
                                                            isShown={state.isShown}
                                                            title="اضافة عميل"
                                                            onCloseComplete={() => setState({ isShown: false })}
                                                            confirmLabel="اضافة"
                                                            cancelLabel="الغاء"
                                                            onConfirm={() => {
                                                                axios.post(host + `users/client/add`,
                                                                    qs.stringify({
                                                                        name: state.name, phone: state.phone, address: state.address
                                                                    }), {
                                                                    headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: cookies.get("token") }
                                                                }).then(response => {
                                                                    setState({ isShown: false })
                                                                    toast('تم اضافة العميل بنجاح', {
                                                                        position: "bottom-center",
                                                                        autoClose: 5000,
                                                                        hideProgressBar: false,
                                                                        closeOnClick: true,
                                                                        pauseOnHover: true,
                                                                        draggable: true
                                                                    });

                                                                }).catch(error => {
                                                                    setState({ isShown: false })
                                                                    console.log(error)
                                                                    toast(error.response.data.message, {
                                                                        position: "bottom-center",
                                                                        autoClose: 5000,
                                                                        hideProgressBar: false,
                                                                        closeOnClick: true,
                                                                        pauseOnHover: true,
                                                                        draggable: true
                                                                    });
                                                                })
                                                            }}
                                                        >
                                                            <div >
                                                                <div id='inputAdd2Continer' >

                                                                    <div id={'ContinerInPut'} >
                                                                        <label> الاسم </label>
                                                                        <input autoFocus autoComplete='off' type='text' placeholder='الاسم' id='InputTExtDash111'
                                                                            onChange={(e) => setState({ name: e.target.value })} />
                                                                    </div>
                                                                    <div id={'ContinerInPut'} >
                                                                        <label>  الهاتف </label>
                                                                        <input autoComplete='off' type='number' placeholder=' الهاتف' id='InputTExtDash111'
                                                                            onChange={(e) => setState({ phone: e.target.value })} />
                                                                    </div>
                                                                    <div id={'ContinerInPut'} >
                                                                        <label> العنوان </label>
                                                                        <input autoComplete='off' type='text' placeholder='العنوان' id='InputTExtDash111'
                                                                            onChange={(e) => setState({ address: e.target.value })} />
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </Dialog>
                                                        <div id='BtnAddٍSetting' style={{ backgroundColor: '#5DADE2' }}
                                                            onClick={() => setState({ isShown: true })}>
                                                            <h6>اضافة عميل</h6>
                                                            <FaUserPlus style={{ fontSize: 40 }} />
                                                        </div>
                                                    </Pane>
                                                )}
                                            </Component>

                                        </Col>

                                        <Col id='colSetting' sm={12} md={6} lg={4} xl={3} >

                                            <Component initialState={{ isShown: false, spinBtn: false, UserId: '', note: '', paymentSelect: '', amount: '', title: '', file: [] }}>
                                                {({ state, setState }) => (
                                                    <Pane>
                                                        <Dialog
                                                            isShown={state.isShown}
                                                            title="اضافة طلب"
                                                            onCloseComplete={() => setState({ isShown: false })}
                                                            confirmLabel="اضافة"
                                                            cancelLabel="الغاء"
                                                            onConfirm={() => {
                                                                var header = { "Content-Type": "application/x-www-form-urlencoded", Authorization: cookies.get("token") };
                                                                let formData = new FormData();
                                                                formData.append("amount", state.amount);
                                                                formData.append("clinet_id", state.UserId);
                                                                formData.append("title", state.title);
                                                                formData.append("description", state.note);
                                                                formData.append("is_out", state.paymentSelect);
                                                                formData.append("file", state.file[0]);
                                                                axios({ url: host + `users/orders/new`, method: "POST", data: formData, headers: header })
                                                                    .then(response => {
                                                                        this.componentDidMount();
                                                                        setState({ isShown: false })
                                                                        toast('تم اضافة الطلب بنجاح', {
                                                                            position: "bottom-center",
                                                                            autoClose: 5000,
                                                                            hideProgressBar: false,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: true
                                                                        });
                                                                    })
                                                                    .catch(error => {
                                                                        setState({ isShown: false })
                                                                        console.log(error)
                                                                        toast(error.response.data.message, {
                                                                            position: "bottom-center",
                                                                            autoClose: 5000,
                                                                            hideProgressBar: false,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: true
                                                                        });
                                                                    })
                                                            }}
                                                        >
                                                            <div >
                                                                <div id='inputAdd2Continer' >
                                                                    <div id={'ContinerInPut'}>
                                                                        <label>الاسم</label>
                                                                        <div id='InputTExtDash111' style={{ paddingRight: 0 }} autoFocus>
                                                                            <Select 
                                                                                onChange={(e) => {
                                                                                    if (e.value !== 'select') {
                                                                                        console.log(e.value);
                                                                                        setState({ UserId: e.value })
                                                                                    }
                                                                                }}
                                                                                defaultValue={[{ value: 'select', label: 'اسم العميل' }]}
                                                                                options={this.state.users}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div id={'ContinerInPut'} >
                                                                        <label> المبلغ </label>
                                                                        <input autoComplete='off' type='number' placeholder='المبلغ' id='InputTExtDash111'
                                                                            onChange={(e) => setState({ amount: e.target.value })} />
                                                                    </div>
                                                                    <div id={'ContinerInPut'} >
                                                                        <label>  المادة </label>
                                                                        <input autoComplete='off' type='text' placeholder=' المادة' id='InputTExtDash111'
                                                                            onChange={(e) => setState({ title: e.target.value })} />
                                                                    </div>
                                                                    <div id={'ContinerInPut'}>
                                                                        <label>تفاصيل</label>
                                                                        <textarea id='InputTExtDash111textarea' cols="100" placeholder="تفاصيل"
                                                                            onChange={(e) => setState({ note: e.target.value })} />
                                                                    </div>

                                                                    <div id={'ContinerInPut'}>
                                                                        <label>النوع</label>
                                                                        <Form.Control as="select" id='InputTExtDash111'
                                                                            onChange={(e) => {
                                                                                if (e.value !== 'select') {
                                                                                    setState({ paymentSelect: e.target.value })
                                                                                }


                                                                            }}>
                                                                            <option value={'select'}>اختيار</option>
                                                                            <option value={0}>دفع</option>
                                                                            <option value={1} >استلام</option>
                                                                        </Form.Control>
                                                                    </div>
                                                                    <div id={'ContinerInPut'}>
                                                                        <label>ملف</label>
                                                                        <FilePicker multiple id='InputTExtDash111' style={{ paddingRight: 0, height: 34 }}
                                                                            onChange={file => { setState({ file }) }} />
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </Dialog>
                                                        <div id='BtnAddٍSetting' style={{ backgroundColor: '#80ce71' }}
                                                            onClick={() => setState({ isShown: true })}>
                                                            <h6>اضافة طلب</h6>
                                                            <MdPlaylistAdd style={{ fontSize: 40 }} />
                                                        </div>
                                                    </Pane>
                                                )}
                                            </Component>

                                        </Col>
                                        <Col id='colSetting' sm={12} md={6} lg={4} xl={3}>
                                            <div id='BtnAddٍSetting' style={{ backgroundColor: '#EC7063' }} onClick={() => {
                                                cookies.remove('token')
                                                window.location.href = '/'
                                            }}>
                                                <h6> تسجيل الخروج</h6>
                                                <FaSignOutAlt style={{ fontSize: 35 }} />
                                            </div>
                                        </Col>
                                    </Row>

                                </div>


                                <ToastContainer
                                    position="bottom-left"
                                    autoClose={10000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnVisibilityChange
                                    draggable
                                    pauseOnHover
                                />
                            </div>
                        )
                    }
                    else {
                        return (
                            <Login />
                        )
                    }
                }
                }

            </Context.Consumer >
        )
    }
}

export default Setting;